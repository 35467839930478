var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-twilio" }, [
    _vm.isFetching
      ? _c(
          "ul",
          _vm._l(parseInt(_vm.count), function (item) {
            return _c("li", { staticClass: "c-employee" })
          }),
          0
        )
      : _c(
          "ul",
          _vm._l(_vm.employees, function (employee, index) {
            return _c(
              "li",
              {
                key: employee.name,
                class: [
                  { "c-twilio__item--dropping": index === _vm.dropIndex },
                  { "c-twilio__item--dragging": index === _vm.draggedIndex },
                  { "c-twilio__item--updating": _vm.isUpdating },
                ],
                attrs: { draggable: "true" },
                on: {
                  dragstart: function ($event) {
                    return _vm.drag(index)
                  },
                  dragover: function ($event) {
                    $event.preventDefault()
                    return _vm.dragover(index)
                  },
                  drop: function ($event) {
                    return _vm.drop(index)
                  },
                  touchstart: function ($event) {
                    return _vm.touch(index, $event)
                  },
                  touchmove: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "prevet",
                        undefined,
                        $event.key,
                        undefined
                      )
                    ) {
                      return null
                    }
                    return _vm.touchover($event)
                  },
                  touchend: _vm.touchdrop,
                },
              },
              [
                _c("Employee", {
                  attrs: { employee: employee, index: index },
                  on: { toggle: _vm.toggle },
                }),
              ],
              1
            )
          }),
          0
        ),
    _vm._v(" "),
    _c("div", { staticClass: "c-twilio__actions" }, [
      _c(
        "div",
        {
          staticClass: "save button",
          on: {
            click: function ($event) {
              return _vm.update()
            },
          },
        },
        [
          _vm.isUpdating
            ? _c("span", { staticClass: "loader" })
            : _c("span", [_vm._v("Gem")]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "revert button",
          on: {
            click: function ($event) {
              return _vm.fetch()
            },
          },
        },
        [_vm._v("Annuller")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }