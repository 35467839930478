var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-employee",
      class: { "c-employee--active": _vm.employee.active },
    },
    [
      _c("div", { staticClass: "image" }, [
        _c("img", {
          attrs: {
            src: _vm.employee.image,
            alt: _vm.employee.name,
            width: "50",
            height: "50",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "info" }, [
        _c("span", { staticClass: "info__name" }, [
          _vm._v(_vm._s(_vm.employee.name)),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "info__phone" }, [
          _vm._v(_vm._s(_vm.employee.phone.display)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "toggle", on: { click: _vm.toggle } }, [
        _c("div", { staticClass: "toggle__dot" }),
      ]),
      _vm._v(" "),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "drag" }, [
      _c("img", { attrs: { src: "/img/drag.svg", alt: "drag" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }