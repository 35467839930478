<template>
  <div class="c-twilio">
    <ul v-if="isFetching">
      <li v-for="item in parseInt(count)" class="c-employee"></li>
    </ul>
    <ul v-else>
      <li
        draggable="true"
        @dragstart="drag(index)"
        @dragover.prevent="dragover(index)"
        @drop="drop(index)"
        @touchstart="touch(index, $event)"
        @touchmove.prevet="touchover($event)"
        @touchend="touchdrop"
        v-for="(employee, index) in employees"
        :key="employee.name"
        :class="[
          { 'c-twilio__item--dropping': index === dropIndex },
          { 'c-twilio__item--dragging': index === draggedIndex },
          { 'c-twilio__item--updating': isUpdating },
        ]"
      >
        <Employee :employee="employee" :index="index" @toggle="toggle" />
      </li>
    </ul>
    <div class="c-twilio__actions">
      <div @click="update()" class="save button">
        <span class="loader" v-if="isUpdating"></span>
        <span v-else>Gem</span>
      </div>
      <div @click="fetch()" class="revert button">Annuller</div>
    </div>
  </div>
</template>

<script>
import { GetTwilio, UpdateTwilio } from "../../api/phone/twilio";
import Employee from "./Employee.vue";

export default {
  name: "Twilio",
  components: { Employee },
  props: {
    count: {
      type: String,
      default: "0",
    },
  },
  data: () => ({
    isUpdating: false,
    isFetching: true,
    draggedIndex: null,
    dropIndex: null,
    touchStartY: null,
    employees: [],
  }),
  methods: {
    drag(index) {
      this.draggedIndex = index;
    },

    touch(index, event) {
      this.draggedIndex = index;
      this.touchStartY = event.touches[0].clientY;
    },

    dragover(index) {
      this.dropIndex = index;
    },

    touchover(event) {
      const currentY = event.touches[0].clientY;
      const deltaY = currentY - this.touchStartY;

      // Calculate the target index based on the distance dragged
      const offset = Math.round(deltaY / 78);
      const targetIndex = Math.max(
        0,
        Math.min(this.draggedIndex + offset, this.employees.length - 1)
      );

      console.log(targetIndex);

      // Update the drop index dynamically
      this.dropIndex = targetIndex;
    },

    drop(target) {
      this.move(target);
    },

    touchdrop() {
      if (this.dropIndex == null) {
        return;
      }

      this.move(this.dropIndex);
      this.reset();
    },

    move(target) {
      if (this.draggedIndex == null) {
        return;
      }

      const draggedItem = this.employees[this.draggedIndex];
      this.employees.splice(this.draggedIndex, 1);
      this.employees.splice(target, 0, draggedItem);

      this.reset();
    },

    reset() {
      this.touchStartY = null;
      this.draggedIndex = null;
      this.dropIndex = null;
    },

    toggle(id) {
      const index = this.employees.findIndex((item) => item.id === id);

      if (index == -1) {
        return;
      }

      this.employees[index].active = !this.employees[index].active;
    },

    async update() {
      this.isUpdating = true;
      let numbers = this.employees
        .filter((item) => item.active)
        .map((item) => item.phone.api);

      if (!numbers.length) {
        return;
      }

      const response = await UpdateTwilio(numbers);
      this.isUpdating = false;

      if (!response.data.success) {
        // Handle error
      }
    },

    async fetch() {
      this.isFetching = true;
      const response = await GetTwilio();
      this.isFetching = false;

      if (!response.data.success) {
        // Handle error
      }

      this.employees = response.data.employees;
    },
  },
  created() {
    console.log(this.count);

    this.fetch();
  },
};
</script>

<style lang="scss">
@import "../../../scss/base/variables.scss";

.c-twilio {
  &__actions {
    margin-top: 35px;
    display: flex;
    gap: 12px;

    .button {
      min-width: 215px;
      cursor: pointer;
      height: 45px;
      border-radius: 22px;
      padding-inline: 74px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      // text-transform: uppercase;

      // border-radius: 20px;

      border: 1px solid $brand-orange;
      box-sizing: border-box;
    }

    .revert {
      border-color: #efefef;
      color: $brand-orange;

      @media (hover: hover) {
        transition: $t-hover;
        &:hover {
          transition: $t-hover;
          border-color: $brand-orange;
        }
      }
    }

    .save {
      background-color: $brand-orange;
      color: $c-white;

      @media (hover: hover) {
        transition: $t-hover;
        &:hover {
          transition: $t-hover;
          color: $brand-orange;
          background-color: transparent;
        }
      }
    }
  }

  &__item {
    transition: $t-hover all;
    opacity: 1;
    &--dropping {
      transition: $t-hover all;
      // background-color: red;
      // padding: 10px;
      position: relative;
      // padding-top: 78px;
      // height: 160px;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        background-color: $c-white;
        border: 1px dotted $brand-orange;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
      }
    }

    &--dragging {
      opacity: 0.7;
    }

    &--updating {
      transition: $t-hover all;
      pointer-events: none !important;
      opacity: 0.4;
    }
  }

  ul {
    all: unset;
    li {
      list-style: none;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }
  }

  .loader {
    width: 65px;
    aspect-ratio: 1;
    --g: radial-gradient(
        farthest-side,
        #0000 calc(95% - 3px),
        #fff calc(100% - 3px) 98%,
        #0000 101%
      )
      no-repeat;
    background: var(--g), var(--g), var(--g);
    background-size: 30px 30px;
    animation: l10 1.5s infinite;

    transform: scale(0.3);
  }
  @keyframes l10 {
    0% {
      background-position: 0 0, 0 100%, 100% 100%;
    }
    25% {
      background-position: 100% 0, 0 100%, 100% 100%;
    }
    50% {
      background-position: 100% 0, 0 0, 100% 100%;
    }
    75% {
      background-position: 100% 0, 0 0, 0 100%;
    }
    100% {
      background-position: 100% 100%, 0 0, 0 100%;
    }
  }
}

@media all and (max-width: 699px) {
  .c-twilio {
    &__actions {
      border-top: 1px solid #efefef;
      margin-top: 0;

      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      flex-wrap: wrap;

      padding: 12px 12px 32px 12px;

      .button {
        flex: 1;
      }
    }
  }
}
</style>
