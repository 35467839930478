<template>
  <div class="c-employee" :class="{ 'c-employee--active': employee.active }">
    <div class="image">
      <img :src="employee.image" :alt="employee.name" width="50" height="50" />
    </div>
    <div class="info">
      <span class="info__name">{{ employee.name }}</span>
      <span class="info__phone">{{ employee.phone.display }}</span>
    </div>
    <div class="toggle" @click="toggle">
      <div class="toggle__dot"></div>
    </div>
    <div class="drag">
      <img src="/img/drag.svg" alt="drag" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TwilioEmployee",

  props: {
    index: {
      type: Number,
      default: 0,
    },
    employee: {
      type: Object,
      default: {
        id: 0,
        image: "",
        active: false,
        name: "",
        phone: {
          api: "",
          display: "",
        },
      },
    },
  },
  data: () => ({}),
  methods: {
    toggle() {
      this.$emit("toggle", this.employee.id);
    },
  },
};
</script>

<style lang="scss">
@import "../../../scss/base/variables.scss";

.c-employee {
  opacity: 0.7;
  display: flex;
  align-items: center;
  gap: 10px;

  height: 78px;
  padding: 14px 15px 14px 15px;
  margin-bottom: 2px;

  background-color: #f5f5f5;
  border-left: 10px solid #d2d2d2;

  .image {
    min-width: 50px;
    width: 50px;
    height: 50px;
    margin-left: 5px;
    border-radius: 100%;
    overflow: hidden;

    display: flex;

    img {
      height: 50px;
      width: auto;
      position: relative;
      left: -9px;
    }
  }

  .info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding-right: 20px;

    span {
      margin: unset;
    }

    &__name {
      font-family: $universeBold;
      color: $brand-green;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 18px;
      margin-bottom: 4px;

      display: -webkit-box;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &__phone {
      white-space: nowrap;
      color: #666666;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;

      display: flex;
      align-items: center;
      gap: 3px;
    }
  }

  .toggle {
    transition: $t-hover all;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-inline: 2px;
    background-color: darken(#f5f5f5, 20);
    width: 50px;
    height: 26px;
    border-radius: 13px;

    &__dot {
      box-sizing: border-box;
      height: 22px;
      width: 22px;
      border-radius: 50%;
      background-color: $c-white;
    }
  }

  .drag {
    pointer-events: none;
    cursor: grab;
    height: 100%;
    width: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: flex;
      height: 18px;
    }
  }

  transition: $t-hover;
  &--active {
    transition: $t-hover;
    opacity: 1;
    border-color: rgb(61, 184, 139);

    .toggle {
      transition: $t-hover all;
      background-color: rgb(61, 184, 139);
      justify-content: flex-end;
    }
  }
}

@media all and (max-width: 400px) {
  .c-employee {
    padding: 14px 12px 14px 5px;

    .image {
      min-width: 40px;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      img {
        height: 40px;
        left: -8px;
      }
    }

    .info {
      gap: 1px;
    }
  }
}
</style>
